@import "_assets/styles/_variables.scss";
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 20px 0;
    :hover {
        background-color: rgba(20, 20, 20, 0.01);
    }
  }
  