@import "_assets/styles/_variables.scss";
.jampp__skeleton {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg,rgba(190,190,190,.2) 25%,rgba(129,129,129,.24) 37%,rgba(190,190,190,.2) 63%);
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: skeleton-loading;
}

@keyframes skeleton-loading{
  0% {
    background-position: 100% 50%
  }
  to{
    background-position: 0 50%
  }
}
