@import "_assets/styles/_variables.scss";
.requests-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.requests-tables {
  width: 100%;
  margin-top: 24px;
  background-color: white;
  height: 100%;
}
