@import "_assets/styles/_variables.scss";
#app-layout {
  display: flex;
  flex-direction: column;
}

.content {
  min-height: 100vh;
  display: flex;
  transition: margin-left $TRANSITION_DELAY;
  background-color: #F9FBFC;
}

.router-children {
  width: 100%;
}


.route {
  padding: 24px;
  max-height: calc(100vh - $STICKY_TOP);
  height: 100%;
  overflow-y: auto;
  position: relative;
}
