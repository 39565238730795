// Colors
$PRIMARY_COLOR: #3159E5;
$SECONDARY_COLOR: #E3E9FF;
$PINK: #F24C6A;
$LIGHT_PINK: #F9A9B8;
$ULTRA_LIGHT_PINK: #FDE2E7;
$LIGHT_BLUE: #4B9EFF;
$DARK_BLUE: #13489E;
$YELLOW: #F5B435;
$GREEN: #15E891;
$LIGHT_GREEN: #aae8cf;
$DARK_GREEN: #11b26f;
$LIGHT-GRAY: #EDEFF5;

// Z-index
$DRAWER_ZINDEX: 25;

// Resolutions
$LAPTOP_WIDTH: 1024px;

// Layout
$TRANSITION_DELAY: 0.4s;
$DRAWER_WIDTH: 310px;
$DRAWER_MOBILE_WIDTH: 280px;
$STICKY_TOP: 112px;
