@import "_assets/styles/_variables.scss";
.drawer {
  width: $DRAWER_WIDTH;
  transition: $TRANSITION_DELAY;
  margin-left: -$DRAWER_WIDTH;
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  border-right: 1px solid #EDEFF5;

  @media (max-width: $LAPTOP_WIDTH) {
    width: $DRAWER_MOBILE_WIDTH;
    position: absolute;
    z-index: $DRAWER_ZINDEX;
    min-height: 100vh;
  }

  &.--opened {
    margin-left: 0;
  }

  nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 12px 8px 12px;
  }
}

.logo-container {
  display: flex;
  height: 63px;
  min-height: 63px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #EDEFF5;
}

.drawer-item {
  span {
    font-weight: 500;
  }

  &.--active {
    background-color: $SECONDARY_COLOR;
    border-radius: 4px;

    svg {
      fill: $PRIMARY_COLOR;
    }
  }
}

.bottom-menu-items {
  margin-top: auto;
}
